import React from 'react';
import { Route, Switch } from 'react-router-dom';

const ErrorPage = React.lazy(() => import('common/ErrorPage'));

// Booker components
const Dashboard = React.lazy(() =>
  import(/* webpackPrefetch: true */ 'booker/components/dashboard')
);
const Booking = React.lazy(() =>
  import(/* webpackPrefetch: true */ 'booker/components/booking')
);
const BookingForm = React.lazy(() =>
  import(/* webpackPrefetch: true */ 'booker/components/forms')
);
const Authentication = React.lazy(() =>
  import(/* webpackPrefetch: true */ 'booker/components/authentication')
);
const PasswordRecovery = React.lazy(() =>
  import('booker/components/passwordRecovery')
);
const ChangePassword = React.lazy(() =>
  import('booker/components/changePassword')
);
const MyDetails = React.lazy(() => import('booker/components/user/MyDetails'));
const FamilySettings = React.lazy(() =>
  import('booker/components/familySettings')
);
const RequestSent = React.lazy(() =>
  import('booker/components/booking/requestSent')
);
const ReviewSent = React.lazy(() =>
  import('booker/components/bookings/reviewSent')
);
const ManageSubscription = React.lazy(() =>
  import('booker/components/subscription')
);
const SubscriptionSuccess = React.lazy(() =>
  import('booker/components/subscription/Success')
);
const Providers = React.lazy(() =>
  import(/* webpackPrefetch: true */ 'booker/components/providers')
);
const ProvideProfile = React.lazy(() =>
  import(/* webpackPrefetch: true */ 'booker/components/providers/Profile')
);
const ShortLists = React.lazy(() =>
  import('booker/components/shortlists/index')
);
const ShortlistDetails = React.lazy(() =>
  import('booker/components/shortlists/ShortlistDetail')
);
const Bookings = React.lazy(() =>
  import(/* webpackPrefetch: true */ 'booker/components/bookings/index')
);
const BookingDetails = React.lazy(() =>
  import(
    /* webpackPrefetch: true */ 'booker/components/bookings/bookingDetails/index'
  )
);

// Provide components
const ProvideDashboard = React.lazy(() =>
  import(/* webpackPrefetch: true */ 'provider/components/dashboard')
);
const Onboarding = React.lazy(() =>
  import(/* webpackPrefetch: true */ 'provider/components/onboarding')
);
const ProvideBookings = React.lazy(() =>
  import('provider/components/bookings')
);
const ProvideAuthentication = React.lazy(() =>
  import(/* webpackPrefetch: true */ 'provider/components/authentication')
);
const ProvideBookingDetails = React.lazy(() =>
  import('provider/components/bookings/bookingDetails')
);
const ProvideMyDetails = React.lazy(() =>
  import('provider/components/user/MyDetails')
);
const ProvidersSchedule = React.lazy(() =>
  import('provider/components/user/ProviderSchedule')
);
const Opportunities = React.lazy(() =>
  import('provider/components/opportunities/index')
);
const OpportunityDetails = React.lazy(() =>
  import('provider/components/opportunities/OpportunityDetails/index')
);
const Rejection = React.lazy(() =>
  import('provider/components/authentication/signIn/Rejection')
);

const AuthenticatedRoute = React.lazy(() =>
  import(/* webpackPrefetch: true */ './AuthenticatedRoute')
);
const CustomRoute = React.lazy(() =>
  import(/* webpackPrefetch: true */ './CustomRoute')
);

const EntryPage = React.lazy(() => import('../EntryPage'));

// const LongTermPlacementOpportunity = React.lazy(() =>
//   import('provider/components/opportunities/LongTermOpportunities')
// );

const Routes = ({ toggleToast }) => {
  return (
    <Switch>
      {/* Common routes */}
      <CustomRoute toggleToast={toggleToast} exact path="/">
        <EntryPage toggleToast={toggleToast} />
      </CustomRoute>
      <CustomRoute exact path="/push-action" />

      {/* Booker routes */}
      <CustomRoute
        exact
        path="/dashboard"
        toggleToast={toggleToast}
        component={Dashboard}
        title="Home"
      />
      <CustomRoute
        exact
        noSideMenu
        path="/sign-in"
        toggleToast={toggleToast}
        component={Authentication}
        title="Sign In"
      />
      <CustomRoute
        exact
        noSideMenu
        path="/sign-up"
        toggleToast={toggleToast}
        component={Authentication}
        title="Join Now"
      />
      <CustomRoute
        exact
        noSideMenu
        path="/password-recovery"
        toggleToast={toggleToast}
        component={PasswordRecovery}
        title="Password Recovery"
      />
      <CustomRoute
        exact
        noSideMenu
        showMenu={false}
        path="/change-password"
        toggleToast={toggleToast}
        component={ChangePassword}
        title="Change Password"
      />
      <CustomRoute
        exact
        showMakeBookingButton
        title="Providers"
        path="/providers"
        toggleToast={toggleToast}
        component={Providers}
      />
      <CustomRoute
        exact
        title="Shortlists"
        path="/shortlists/:uuid"
        toggleToast={toggleToast}
        component={ShortlistDetails}
      />
      <CustomRoute
        exact
        title="Providers"
        path="/providers/:providerId"
        toggleToast={toggleToast}
        component={ProvideProfile}
      />
      <Route
        exact
        path="/our-story"
        component={() => {
          window.location.replace('https://callemmy.com/about/');
          return null;
        }}
      />
      <Route
        exact
        path="/team"
        component={() => {
          window.location.replace('https://callemmy.com/team/');
          return null;
        }}
      />
      <Route
        exact
        path="/blog"
        component={() => {
          window.location.replace('https://callemmy.com/blog/');
          return null;
        }}
      />
      <Route
        exact
        path="/apply"
        component={() => {
          window.location.replace('https://callemmy.com/apply/');
          return null;
        }}
      />
      <Route
        exact
        path="/safety"
        component={() => {
          window.location.replace('https://callemmy.com/safety/');
          return null;
        }}
      />
      <Route
        exact
        path="/covid"
        component={() => {
          window.location.replace('https://callemmy.com/covid/');
          return null;
        }}
      />
      <Route
        exact
        path="/call-emmy-promise"
        component={() => {
          window.location.replace('https://callemmy.com/call-emmy-promise/');
          return null;
        }}
      />
      <Route
        exact
        path="/services"
        component={() => {
          window.location.replace('https://callemmy.com/#services');
          return null;
        }}
      />
      <Route
        exact
        path="/referral-program"
        component={() => {
          window.location.replace('https://callemmy.com/referral-program/');
          return null;
        }}
      />
      <Route
        exact
        path="/press"
        component={() => {
          window.location.replace('https://hello.callemmy.com/press/');
          return null;
        }}
      />
      <Route
        exact
        path="/privacy-policy"
        component={() => {
          window.location.replace('https://www.callemmy.com/privacy-policy');
          return null;
        }}
      />
      <Route
        exact
        path="/terms-of-service"
        component={() => {
          window.location.replace('https://callemmy.com/terms-of-use/');
          return null;
        }}
      />
      <Route
        exact
        path="/faq"
        component={() => {
          window.location.replace('https://www.callemmy.com/faqs');
          return null;
        }}
      />
      <Route
        exact
        path="/faq-partners"
        component={() => {
          window.location.replace('https://callemmy.com/faqs-partners/');
          return null;
        }}
      />
      <Route
        exact
        path="/contact"
        component={() => {
          window.location.replace('https://callemmy.com/contact/');
          return null;
        }}
      />
      <Route
        exact
        path="/how-it-works"
        component={() => {
          window.location.replace('https://www.callemmy.com/#how-it-works');
          return null;
        }}
      />
      <Route
        exact
        path="/facebook"
        component={() => {
          window.location.replace('https://www.facebook.com/callemmyapp');
          return null;
        }}
      />
      <Route
        exact
        path="/instagram"
        component={() => {
          window.location.replace(
            'https://www.instagram.com/accounts/login/?next=/callemmyapp/'
          );
          return null;
        }}
      />
      <Route
        exact
        path="/twitter"
        component={() => {
          window.location.replace('https://twitter.com/callemmyapp');
          return null;
        }}
      />
      <Route
        exact
        path="/linkedin"
        component={() => {
          window.location.replace(
            'https://www.linkedin.com/company/callemmyapp'
          );
          return null;
        }}
      />
      <Route
        exact
        path="/pinterest"
        component={() => {
          window.location.replace('https://www.pinterest.com/callemmyapp/');
          return null;
        }}
      />
      <Route
        exact
        path="/blog"
        component={() => {
          window.location.replace('https://callemmy.com/blog/');
          return null;
        }}
      />
      <Route
        exact
        path="/safety"
        component={() => {
          window.location.replace('https://callemmy.com/safety/');
          return null;
        }}
      />
      <Route
        exact
        path="/my-promise"
        component={() => {
          window.location.replace('https://callemmy.com/call-emmy-promise/');
          return null;
        }}
      />
      <AuthenticatedRoute
        exact
        showMakeBookingButton
        path="/shortlists"
        toggleToast={toggleToast}
        component={ShortLists}
        title="Shortlists"
      />
      <AuthenticatedRoute
        exact
        showMakeBookingButton
        path="/bookings"
        toggleToast={toggleToast}
        component={Bookings}
        title="Bookings"
      />
      <AuthenticatedRoute
        exact
        showMakeBookingButton
        path="/bookings/:id"
        toggleToast={toggleToast}
        component={BookingDetails}
        title="Bookings"
      />
      <AuthenticatedRoute
        exact
        fullPage
        splashPage
        path="/booking/request-sent"
        toggleToast={toggleToast}
        component={RequestSent}
        title="Booking request sent"
      />
      <AuthenticatedRoute
        exact
        fullPage
        path="/review-sent"
        toggleToast={toggleToast}
        component={ReviewSent}
        title="Review sent"
      />
      <AuthenticatedRoute
        exact
        path="/booking/:step"
        toggleToast={toggleToast}
        component={Booking}
        title="Booking"
      />
      <AuthenticatedRoute
        exact
        path="/hire-a-nanny/:id"
        toggleToast={toggleToast}
        component={BookingForm}
        title="Hire a Nanny"
      />
      <AuthenticatedRoute
        exact
        path="/my-details"
        toggleToast={toggleToast}
        component={MyDetails}
        title="Account Info"
      />
      <AuthenticatedRoute
        exact
        path="/family-settings"
        toggleToast={toggleToast}
        component={FamilySettings}
        title="Family Info"
      />
      <AuthenticatedRoute
        exact
        showMakeBookingButton
        path="/subscription"
        toggleToast={toggleToast}
        component={ManageSubscription}
        title="Upgrade to Call Emmy Pass"
      />
      <AuthenticatedRoute
        exact
        fullPage
        path="/subscription-upgraded"
        toggleToast={toggleToast}
        component={SubscriptionSuccess}
        title=""
      />
      <AuthenticatedRoute
        exact
        showMakeBookingButton
        path="/providers"
        toggleToast={toggleToast}
        component={Providers}
        title="Browse Providers"
      />
      {/*
        Provide paths go below
        All provider's paths start with /provider and have providerPage property
      */}
      <CustomRoute
        exact
        providerPage
        noSideMenu
        path="/provider/sign-in"
        toggleToast={toggleToast}
        component={ProvideAuthentication}
        title="Provider Hub"
      />
      <CustomRoute
        exact
        providerPage
        noSideMenu
        path="/provider/sign-up"
        toggleToast={toggleToast}
        component={ProvideAuthentication}
        title="Provider Hub"
      />
      <CustomRoute
        exact
        providerPage
        path="/provider/dashboard"
        component={ProvideDashboard}
        toggleToast={toggleToast}
        title="Home"
      />
      <Route
        exact
        path="/provider/faq"
        component={() => {
          window.location.replace('https://www.callemmy.com/faqs-providers/');
          return null;
        }}
      />
      <Route
        exact
        path="/provider/share"
        component={() => {
          window.location.replace('https://www.callemmy.com/faqs-providers/');
          return null;
        }}
      />
      <Route exact path="/provider/champion">
        <>
          <h2>Champions of Call Emmy</h2>
          <p>Coming Soon</p>
        </>
      </Route>

      <AuthenticatedRoute
        exact
        providerPage
        path="/provider/bookings"
        toggleToast={toggleToast}
        component={ProvideBookings}
        title="Bookings"
      />
      <AuthenticatedRoute
        exact
        providerPage
        path="/provider/opportunities"
        toggleToast={toggleToast}
        component={Opportunities}
        title="Opportunities"
      />
      <AuthenticatedRoute
        exact
        providerPage
        path="/provider/opportunities/:id"
        toggleToast={toggleToast}
        component={OpportunityDetails}
        title="Opportunities"
      />
      <AuthenticatedRoute
        exact
        providerPage
        path="/provider/my-details"
        toggleToast={toggleToast}
        component={ProvideMyDetails}
        title="Account Info"
      />
      <AuthenticatedRoute
        exact
        providerPage
        path="/provider/my-schedule"
        toggleToast={toggleToast}
        component={ProvidersSchedule}
        title="My Availability"
      />
      <AuthenticatedRoute
        exact
        providerPage
        path="/provider/onboarding/getting-started"
        toggleToast={toggleToast}
        component={Onboarding}
        title="Join Call Emmy as a Provide"
      />
      <AuthenticatedRoute
        exact
        providerPage
        path="/provider/onboarding/more-about-you"
        toggleToast={toggleToast}
        component={Onboarding}
        title="Join Call Emmy as a Provide"
      />
      <AuthenticatedRoute
        exact
        providerPage
        path="/provider/onboarding/phone-number"
        toggleToast={toggleToast}
        component={Onboarding}
        title="Join Call Emmy as a Provide"
      />
      <AuthenticatedRoute
        exact
        providerPage
        path="/provider/onboarding/super-powers"
        toggleToast={toggleToast}
        component={Onboarding}
        title="Join Call Emmy as a Provide"
      />
      <AuthenticatedRoute
        exact
        providerPage
        path="/provider/onboarding/references"
        toggleToast={toggleToast}
        component={Onboarding}
        title="Join Call Emmy as a Provide"
      />
      <AuthenticatedRoute
        exact
        providerPage
        path="/provider/onboarding/skills-test"
        toggleToast={toggleToast}
        component={Onboarding}
        title="Join Call Emmy as a Provide"
      />
      <AuthenticatedRoute
        exact
        providerPage
        path="/provider/onboarding/schedules"
        toggleToast={toggleToast}
        component={Onboarding}
        title="Join Call Emmy as a Provide"
      />
      <AuthenticatedRoute
        exact
        providerPage
        path="/provider/onboarding/personality"
        toggleToast={toggleToast}
        component={Onboarding}
        title="Join Call Emmy as a Provide"
      />
      <AuthenticatedRoute
        exact
        providerPage
        path="/provider/onboarding/identity-verification"
        toggleToast={toggleToast}
        component={Onboarding}
        title="Join Call Emmy as a Provide"
      />
      <AuthenticatedRoute
        exact
        providerPage
        path="/provider/onboarding/ach"
        toggleToast={toggleToast}
        component={Onboarding}
        title="Join Call Emmy as a Provide"
      />
      <AuthenticatedRoute
        exact
        providerPage
        path="/provider/onboarding/create-profile"
        toggleToast={toggleToast}
        component={Onboarding}
        title="Join Call Emmy as a Provide"
      />
      <AuthenticatedRoute
        exact
        providerPage
        path="/provider/bookings/:id"
        toggleToast={toggleToast}
        component={ProvideBookingDetails}
        title="Bookings"
      />
      <AuthenticatedRoute
        exact
        providerPage
        path="/provider/ineligible"
        toggleToast={toggleToast}
        component={Rejection}
        title="Rejection"
        fullPage
        noNav
      />

      {/* 404 not found */}
      <Route path="*">
        <ErrorPage resetError={() => null} />
      </Route>
    </Switch>
  );
};

export default Routes;
